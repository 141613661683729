export default {
  setById: (state, payload) => {
    state.itemById = payload;
  },

  setFiles: (state, payload) => {
    if (payload) {
      state.files = payload;

      state.files.forEach(file => {
        file.visible = true;
      });
    } else {
      state.files = [];
    }
  },

  setMessages: (state, payload) => {
    if (payload) {
      state.messages = payload;
    } else {
      state.messages = [];
    }
  },

  filteredFiles: (state, payload) => {
    state.files.forEach(file => {
      file.visible = true;
    });

    if (payload) {
      state.files.forEach(file => {
        if (!payload.includes(file.id)) {
          file.visible = false;
        }
      });

      state.showCleanFilter = true;
    } else {
      state.showCleanFilter = false;
    }
  }
};
