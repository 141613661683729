<template>
  <div class="b-company">
    <div
      class="b-company__avatar"
      :class="imagePreview && 'b-company__avatar--shadow'"
    >
      <img v-if="imagePreview" :src="imagePreview" alt="Logotipo da Empresa" />
      <div class="b-company__avatar__upload">
        <div>
          <input
            ref="input"
            type="file"
            class="d-none"
            accept="image/*"
            @change="handleFile($event)"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                class="button-upload"
                type="button"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="openFileDialog()"
              >
                <v-icon small>
                  {{ imagePreview ? 'fa-solid fa-pencil' : 'fa-solid fa-plus' }}
                </v-icon>
              </v-btn>
            </template>
            <span>Tamanho máximo de 300x300 pixels, com fundo branco</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],

  data: () => ({
    imagePreview: ''
  }),

  mounted() {
    this.createImagePreview();
  },

  methods: {
    createImagePreview() {
      if (this.value instanceof File) {
        this.imagePreview = URL.createObjectURL(this.value);
      } else {
        this.imagePreview = this.value;
      }
    },

    openFileDialog() {
      this.$refs.input.value = null;
      this.$refs.input.click();
    },

    handleFile({ target }) {
      this.imagePreview = URL.createObjectURL(target.files[0]);

      this.$emit('input', target.files[0]);
    }
  },

  watch: {
    value() {
      this.createImagePreview();
    }
  }
};
</script>

<style lang="scss">
.b-company {
  &__avatar {
    position: relative;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 0 auto 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray--20;

    &--shadow {
      background: #ffffff;
    }

    .theme--light.v-application &--shadow {
      box-shadow: 0px 0px 3px 2px #ccc;
    }

    .theme--dark.v-application &--shadow {
      box-shadow: none;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }

    &__upload {
      position: absolute;
      bottom: 0;
      border-radius: 50%;
      right: -10px;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .theme--light.v-application &__upload {
      background: #fff;
    }

    .theme--dark.v-application &__upload {
      background: $bg-dark-elements;
    }
  }

  .button-upload {
    width: 32px;
    height: 32px;
  }
}
</style>
