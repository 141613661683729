import getters from './getters';
import mutations from './mutations';

export const support = {
  namespaced: true,
  state: {
    itemById: {},
    showCleanFilter: false,
    files: [],
    messages: []
  },
  getters,
  mutations
};
