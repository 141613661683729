<template>
  <vuetify-money
    dense
    background-color="transparent"
    :outlined="outlined"
    :value="value"
    :valueWhenIsEmpty="''"
    :options="options"
    v-bind="$attrs"
    @input="onInput"
    @change="onChange"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, null],
      require: true
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    options: {
      locale: 'pt-BR',
      suffix: '$',
      length: 11,
      precision: 2
    }
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event ? parseFloat(event) : event);
    },

    onChange() {
      this.$emit('change', this.value ? parseFloat(this.value) : this.value);
    }
  }
};
</script>
