import getters from './getters';
import mutations from './mutations';

const tabs = [
  {
    href: 'tab-1',
    title: 'Fatura',
    component: 'PageInvoice',
    visible: true
  },
  {
    href: 'tab-2',
    title: 'Movimentos',
    component: 'PageMovements',
    visible: true
  },
  {
    href: 'tab-3',
    title: 'Fatura unificada',
    component: 'PageUnifiedInvoice',
    visible: false
  },
  {
    href: 'tab-4',
    title: 'Documentos',
    component: 'PageDocuments',
    visible: true
  },
  {
    href: 'tab-5',
    title: 'Particularidades',
    component: 'PageParticularities',
    visible: true
  }
];

export const invoice = {
  namespaced: true,
  state: {
    invoice: {},
    tabs: tabs
  },
  getters,
  mutations
};
