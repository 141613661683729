<template>
  <v-menu
    min-width="290px"
    transition="scale-transition"
    :close-on-content-click="false"
    v-model="menu"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        dense
        outlined
        readonly
        append-icon="fa-regular fa-calendar-days"
        :label="label"
        :value="computedDateFormatted"
        v-on="{ ...on, ...$listeners }"
        v-bind="$attrs"
      />
    </template>

    <v-date-picker
      @change="onChange"
      @input="onInput"
      v-model="date"
      :allowed-dates="allowedDates"
      color="primary"
      locale="pt-br"
      type="month"
      no-title
    />
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Date],
      require: true
    },
    allowedMonths: {
      type: Function,
      require: false,
      default: () => true
    }
  },

  data: () => ({
    menu: false,
    date: null
  }),

  computed: {
    computedDateFormatted() {
      if (this.value) {
        this.setDate(this.value);
      }

      return this.formatDate(this.date);
    }
  },

  watch: {
    value(newValue) {
      this.setDate(newValue);
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event);
      this.menu = false;
    },

    onChange() {
      this.$emit('change', this.date);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month] = date.split('-');

      return `${month}/${year}`;
    },

    setDate(newValue) {
      this.date = newValue
        ? new Date(newValue).toISOString().substr(0, 7)
        : null;
    },

    allowedDates(val) {
      return this.allowedMonths(val);
    }
  }
};
</script>
