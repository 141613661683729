const routes = [
  {
    path: '/newPassword/:token',
    meta: {
      public: true,
      title: 'Redefinição de Senha'
    },
    name: 'newPassword',
    component: () => import('@/views/Account/NewPassword/Index.vue')
  },
  {
    path: '/activateUser/:token',
    meta: {
      public: true,
      title: 'Ativação de conta do usuário'
    },
    name: 'activateUser',
    component: () => import('@/views/Account/ActivateUser/Index.vue')
  },
  {
    path: '/redirect',
    meta: {
      public: true,
      title: 'Redirecionamento'
    },
    name: 'redirect',
    component: () => import('@/views/Account/Redirect/Index.vue')
  }
];

export default routes;
