<template>
  <v-text-field
    dense
    outlined
    :loading="isLoading"
    :value="value"
    v-bind="$attrs"
    v-mask="'#####-###'"
    @blur="search"
    @input="onInput"
  >
    <template v-slot:append>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="primary"
            v-on="on"
            @click="openCepSearch"
            id="cepSearch"
          >
            fa-solid fa-location-dot
          </v-icon>
        </template>
        <span>Não sabe seu CEP? Clique aqui!</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { ZipcodeService } from '@/services/api/zipcode';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, null],
      require: true
    }
  },

  data: () => ({
    isLoading: false
  }),

  methods: {
    async search() {
      this.isLoading = true;

      try {
        const value = unmask(this.value);

        if (value && value.length >= 8) {
          const zipcodeService = new ZipcodeService(false);
          const { data } = await zipcodeService.getZipcode(value);

          if (data.erro) {
            showMessage('error', `O CEP ${value} é inválido!`, 3000);
          } else {
            this.$emit('fill', data);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    openCepSearch() {
      window.open('https://buscacepinter.correios.com.br/', '_blank');
    },

    onInput(event) {
      this.$emit('input', event);
    }
  }
};
</script>
