export default {
  createTabs: (state, payload) => {
    if (payload.productType === 'Seguro de vida') {
      state.contractTabs[3].visible = true;
      state.contractTabs[4].visible = false;
    } else {
      state.contractTabs[3].visible = false;
      state.contractTabs[4].visible = true;
    }

    if (payload.hasLeaveBeneficiaries) {
      state.contractTabs[7].visible = true;
    } else {
      state.contractTabs[7].visible = false;
    }

    if (payload.id || payload.contractId) {
      state.contractTabs.forEach(x => (x.disabled = false));
    } else {
      state.contractTabs.forEach(x => (x.disabled = true));
      state.contractTabs[0].disabled = false;
    }
  },

  contractById: (state, payload) => {
    state.contractById = payload;
  }
};
