import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/indexMovements',
    meta: {
      public: false,
      title: 'Movimentações'
    },
    redirect: '/movimentacoes',
    component: () =>
      import('@/views/OperationalManagement/Movements/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2', 'C3', 'F1', 'F2', 'F3'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    },
    children: [
      {
        path: '/movimentacoes',
        meta: {
          public: false,
          title: 'Movimentações'
        },
        name: 'movimentacoes',
        component: () =>
          import('@/views/OperationalManagement/Movements/Search.vue')
      },
      {
        path: '/movimentacoes/list/:customerId?',
        meta: {
          public: false,
          title: 'Movimentações - Consulta'
        },
        name: 'movimentacoes-list',
        component: () =>
          import('@/views/OperationalManagement/Movements/List.vue')
      },
      {
        path: '/movimentacoes/edit/:customerId?/:employeeId?/:employeeOrDependentId?',
        meta: {
          public: false,
          title: 'Movimentações - Gestão do titular e/ou dependente'
        },
        name: 'movimentacoes-edit',
        component: () =>
          import('@/views/OperationalManagement/Movements/Edit.vue')
      },
      {
        path: '/movimentacoes/new/:customerId?/:employeeId?/:returnUrl?',
        meta: {
          public: false,
          title: 'Movimentações - Cadastro'
        },
        name: 'movimentacoes-new',
        component: () =>
          import('@/views/OperationalManagement/Movements/New.vue')
      },
      {
        path: '/movimentacoes/card/:customerId?/:employeeId?/:employeeOrDependentId?/:title?/:component?',
        meta: {
          public: false,
          title: 'Movimentações'
        },
        name: 'movimentacoes-card',
        component: () =>
          import('@/views/OperationalManagement/Movements/Card.vue')
      }
    ]
  }
];

export default routes;
