<template>
  <v-autocomplete
    item-text="description"
    item-value="id"
    multiple
    dense
    outlined
    :loading="isLoading"
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" class="caption">
        {{ item.description }}
      </span>
      <span v-if="index === 1" class="grey--text caption ml-1">
        (+{{ value.length - 1 }} contratos)
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { MovementService } from '@/services/api/movements';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Array, String],
      require: true
    },
    employeeId: {
      type: String,
      require: true
    },
    dependentId: {
      type: String,
      require: false
    }
  },

  data: () => ({
    isLoading: false,
    items: []
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.isLoading = true;

      try {
        const service = new MovementService(false);
        const { status, data } = await service.getAvailableDependentsContracts({
          employeeId: this.employeeId,
          dependentId: this.dependentId
        });

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
