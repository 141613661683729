<template>
  <v-row v-bind="$attrs">
    <v-col>
      <div class="b_list">
        <div class="b_list__text" v-if="title">
          <h4 class="b_list__text--title">
            {{ title }}
          </h4>

          <h6 class="b_list__text--subtitle" v-if="subtitle">
            <slot name="subtitle"></slot>
          </h6>

          <div v-if="right">
            <slot name="right"></slot>
          </div>
        </div>

        <slot name="table"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      require: false
    },
    subtitle: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .b_list {
    &__text {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid;

      &--title {
        font-size: 1rem;
        font-weight: 400;
      }

      &--subtitle {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    .v-data-table {
      border-radius: 0;

      > .v-data-table__wrapper
        > table
        > tbody
        > tr.v-data-table__expanded.v-data-table__expanded__row
        > td {
        font-weight: 600;
      }
    }

    .b_datatable-cards {
      > .v-data-table__wrapper
        > table
        > tbody
        > tr.v-data-table__expanded.v-data-table__expanded__row
        > td {
        font-weight: 400;
      }
    }
  }

  &.theme--dark {
    .b_list {
      box-shadow: 0px 0px 2px 1px $shadow-dark;
      background-color: #363636;

      &__text {
        border-color: $gray--40;

        &--title {
          color: $gray--20;
        }
      }

      .b_list__card-table__progress {
        border-left: 1px solid $gray--40;
        border-right: 1px solid $gray--40;
      }

      .b_datatable-cards {
        > .v-data-table__wrapper
          > table
          > tbody
          > tr.v-data-table__expanded.v-data-table__expanded__content {
          box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
        }
      }
    }

    .v-data-table {
      > .v-data-table__wrapper > table > tbody > tr {
        &:nth-child(even) {
          background-color: #2c2c2c;
        }
      }
    }
  }

  &.theme--light {
    .b_list {
      box-shadow: 0px 0px 2px 1px $shadow-light;
      background-color: #fff;

      &__text {
        border-color: $gray--20;

        &--title {
          color: $gray--40;
        }
      }

      .b_list__card-table__progress {
        border-left: 1px solid $gray--20;
        border-right: 1px solid $gray--20;
      }

      .b_datatable-cards {
        > .v-data-table__wrapper
          > table
          > tbody
          > tr.v-data-table__expanded.v-data-table__expanded__content {
          box-shadow: 0px 0px 5px rgba(51, 48, 62, 0.2);
        }
      }
    }

    .v-data-table {
      > .v-data-table__wrapper > table > tbody > tr {
        &:nth-child(even) {
          background-color: $gray--10;
        }
      }
    }
  }
}
</style>
