import { HttpClient } from '@/services/http';

export class UserService extends HttpClient {
  async getUsers(params) {
    const result = await this.get('v1/users', params);
    return result;
  }

  async postUser(params) {
    const result = await this.post('v1/users', params);
    return result;
  }

  async putUser(params) {
    const result = await this.put('v1/users', params);
    return result;
  }

  async getById(id) {
    const result = await this.get(`v1/users/${id}`);
    return result;
  }

  async getProfiles() {
    const result = await this.get('v1/users/profiles');
    return result;
  }

  async getRoles(profile) {
    const result = await this.get(`v1/users/profiles/${profile}/roles`);
    return result;
  }

  async usersByBusinessUnit(id) {
    const result = await this.get(`v1/users/businessUnit/${id}`);
    return result;
  }

  async sendEmail(id) {
    const result = await this.post(`v1/account/user/${id}/resend-email`);
    return result;
  }
}
