import { HttpClient } from '@/services/http';

export class CustomerService extends HttpClient {
  async getCustomers(params) {
    const result = await this.get('v1/customers', params);
    return result;
  }

  async postCustomer(params) {
    const result = await this.post('v1/customers', params);
    return result;
  }

  async putCustomer(params) {
    const result = await this.put('v1/customers', params);
    return result;
  }

  async getCustomerById(params) {
    if (params.withChildren) {
      const result = await this.get(
        `v1/customers/${params.id}?withChildren=true`
      );
      return result;
    }

    const result = await this.get(`v1/customers/${params.id}`);
    return result;
  }

  async getCnaes(params) {
    const result = await this.get('v1/customers/cnaes', params);
    return result;
  }

  async getLegalOrigins(params) {
    const result = await this.get('v1/customers/legal-origins', params);
    return result;
  }

  async getCustomersByBusinessUnit(id) {
    const result = await this.get(`v1/customers/businessUnit/${id}`);
    return result;
  }

  async getCustomersByCustomerManager() {
    const result = await this.get(
      'v1/customers/customer-manager/available-customers'
    );
    return result;
  }

  async getMainContact(id) {
    const result = await this.get(`v1/customers/${id}/main-contact`);
    return result;
  }
}
