<template>
  <v-autocomplete
    dense
    outlined
    item-text="socialName"
    item-value="id"
    :loading="isLoading"
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
import { CustomerService } from '@/services/api/customers';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    },
    sub: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String
    }
  },

  data: () => ({
    isLoading: false,
    items: []
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      if (this.sub) {
        this.searchSub();
      } else {
        this.isLoading = true;

        try {
          const service = new CustomerService(false);
          const { status, data } = await service.getCustomers({
            page: 1,
            take: 50
          });

          if (status === 200) {
            this.items = data.results;
          }
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    async searchSub() {
      this.isLoading = true;

      try {
        const service = new CustomerService(false);
        const { status, data } = await service.getCustomerById({
          id: this.customerId,
          withChildren: true
        });

        if (status === 200) {
          this.items = data.children;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
