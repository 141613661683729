<template>
  <v-autocomplete
    dense
    outlined
    item-value="id"
    item-text="description"
    :loading="isLoading"
    :search-input.sync="search"
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @input="onInput"
    @change="onChange"
  />
</template>

<script>
import { CustomerService } from '@/services/api/customers';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    },
    cnae: {
      type: Object
    }
  },

  data: () => ({
    isLoading: false,
    search: null,
    items: []
  }),

  methods: {
    async searchCnaes(description) {
      this.isLoading = true;

      try {
        const service = new CustomerService(false);
        const { status, data } = await service.getCnaes({
          description
        });

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  },

  watch: {
    search(newValue) {
      if (newValue && newValue.length >= 3) {
        this.searchCnaes(newValue);
      }
    },

    cnae(newValue) {
      if (newValue && newValue.description) {
        this.searchCnaes(newValue.description);
      }
    }
  }
};
</script>
