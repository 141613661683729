const routes = [
  {
    path: '/integracoes',
    name: 'integracoes',
    meta: {
      public: false,
      title: 'Integrações'
    },
    component: () =>
      import('@/views/OperationalManagement/Integration/Index.vue')
  }
];

export default routes;
