import store from '@/store';

const remove = el => {
  el.remove();
};

const disabledElement = (el, binding) => {
  if ('disabled' in binding.modifiers) {
    el.setAttribute('disabled', 'disabled');

    if (el.type === 'fieldset') {
      let inputs = el.querySelectorAll('.v-input');

      inputs.forEach(element => {
        addClassElements(element);
      });
    } else {
      addClassElements(el);
    }
  }
};

const addClassElements = el => {
  if (el.classList.contains('v-input')) {
    el.classList.add('v-input--is-disabled');

    let labels = el.getElementsByTagName('label');
    let inputs = el.getElementsByTagName('input');

    if (labels.length > 0) {
      for (let index = 0; index < labels.length; index++) {
        const element = labels[index];

        if (element.classList.contains('v-label')) {
          element.classList.add('v-label--is-disabled');
        }
      }
    }

    if (inputs.length > 0) {
      for (let index = 0; index < inputs.length; index++) {
        const element = inputs[index];

        if (element.getAttribute('type') === 'text') {
          element.setAttribute('disabled', 'disabled');
        }
      }
    }
  }
};

const permission = {
  inserted: function (el, binding, vnode) {
    setTimeout(() => {
      const { permission } = store.getters['authentication/getProfile'];
      const { roles, operator } = binding.value;

      if (!('disabled' in binding.modifiers)) {
        if (operator === '!==') {
          if (roles.includes(permission)) {
            remove(vnode.elm);
          }
        } else {
          if (!roles.includes(permission)) {
            remove(vnode.elm);
          }
        }
      }
    }, 500);
  },
  update: function (el, binding) {
    setTimeout(() => {
      const { permission } = store.getters['authentication/getProfile'];
      const { roles, operator } = binding.value;

      if ('disabled' in binding.modifiers) {
        if (operator === '!==') {
          if (roles.includes(permission)) {
            disabledElement(el, binding);
          }
        } else {
          if (!roles.includes(permission)) {
            disabledElement(el, binding);
          }
        }
      }
    }, 500);
  }
};

export default permission;
