<template>
  <v-row class="b-pages">
    <v-col class="b-pages__content">
      <h4 class="b-pages__content--title" v-html="verifyName()" />
      <slot name="button" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },

  methods: {
    verifyName() {
      if (this.title) {
        return this.title;
      } else if (this.$route.meta.title) {
        return this.$route.meta.title;
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .b-pages {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &__content {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;

      &--title {
        font-size: 1.4rem;
        line-height: 20px;

        strong {
          font-weight: 500;
        }
      }

      &--resume {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__sub {
        display: flex;
        flex-direction: column;
        text-align: right;

        &--title {
          font-size: 1rem;
        }

        span {
          font-size: 0.9rem;
          margin: 2px 0;

          &:last-child {
            color: var(--v-success-base);
            font-weight: 500;
          }
        }
      }
    }
  }
}

.b-title__icon {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-left: 0.2rem;
  }
}

.theme--light {
  .b-pages__content--title {
    color: $gray--60;
  }
}

.theme--dark {
  .b-pages__content--title {
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .biobe {
    .b-pages {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0;
        padding-bottom: 0;

        &--title {
          font-size: 1rem;
        }

        &--resume {
          align-items: flex-start;
        }

        &__sub {
          text-align: left;
        }
      }
    }
  }
}
</style>
