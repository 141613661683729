<template>
  <v-tooltip bottom :disabled="!tooltip">
    <template v-slot:activator="{ on }">
      <v-btn
        class="b-button"
        depressed
        :block="block"
        :type="type"
        :color="color"
        :height="height"
        v-bind="$attrs"
        v-on="{ ...on, ...$listeners }"
        @click="executeAction()"
      >
        {{ title }}
      </v-btn>
      <input
        ref="input"
        type="file"
        class="d-none"
        @change="handleFile($event)"
      />
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      require: true
    },

    tooltip: {
      type: String,
      require: true
    },

    type: {
      type: String,
      default: 'button'
    },

    color: {
      type: String,
      require: true
    },

    block: {
      type: Boolean,
      default: false
    },

    height: {
      type: String,
      default: '42'
    },

    item: {
      type: Object
    }
  },

  methods: {
    executeAction() {
      this.$refs.input.value = null;
      this.$refs.input.click();
    },

    handleFile({ target }) {
      let document = { ...this.item, ...{ file: target.files[0] } };

      this.$emit('uploadFile', document);
    }
  }
};
</script>
