export default {
  setInvoice: (state, payload) => {
    if (payload) {
      state.invoice = payload;

      state.tabs[2].visible = payload.hasSub;
    } else {
      state.invoice = {};
    }
  }
};
