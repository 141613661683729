import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/indexContracts',
    meta: {
      public: false,
      title: 'Contratos'
    },
    redirect: '/contratos',
    component: () =>
      import('@/views/OperationalManagement/Contracts/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    },
    children: [
      {
        path: '/contratos',
        meta: {
          public: false,
          title: 'Consultar contratos'
        },
        name: 'contratos',
        component: () =>
          import('@/views/OperationalManagement/Contracts/List.vue')
      },
      {
        path: '/contratos/create-or-edit/:id?',
        meta: {
          public: false,
          title: 'Visualizar contrato'
        },
        name: 'contratos-edit',
        component: () =>
          import('@/views/OperationalManagement/Contracts/Edit.vue')
      }
    ]
  }
];

export default routes;
