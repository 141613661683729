export const unmask = text => {
  if (text) {
    const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/;

    return String(text).replace(new RegExp(defaultDelimiters, 'g'), '');
  }

  return null;
};

export const getDaysColor = value => {
  if (!value || value === 1) {
    return null;
  }

  if (value >= 2 && value <= 4) {
    return 'warning--text';
  }

  if (value >= 5) {
    return 'error--text';
  }
};

export const doTruncateText = (text, limit) => {
  if (!text || text.length < limit) return text;

  return `${text.substring(0, limit)}...`;
};

export const getColor = status => {
  let color = '';

  switch (status) {
    case 'Ativo':
    case 'Concluído':
    case 'Aprovado':
    case 'Finalizado':
    case 'Resolvido':
      color = 'success--text';
      break;
    case 'Inativo':
    case 'Cancelado':
    case 'Reprovado':
    case 'Erro':
      color = 'error--text';
      break;
    case 'Em Revisão':
    case 'Aguardando':
    case 'Aguardando operadora':
      color = 'warning--text';
      break;
    case 'Novo':
    case 'Em Processo':
      color = 'info--text';
      break;
    case 'Em andamento':
      color = 'primary--text';
      break;
    default:
      color = '';
      break;
  }

  return color;
};

export const checkApi = url => {
  return url.includes('https://viacep.com.br/');
};

export const getProductTypeName = name => {
  let type = '';

  switch (name) {
    case 'Assistência odontológica':
      type = 'Plano odontológico';
      break;
    case 'Assistência médica':
      type = 'Plano de saúde';
      break;
    default:
      type = 'Seguro de vida';
      break;
  }

  return type;
};

export const getProductTypeIcon = name => {
  let icon = '';

  switch (name) {
    case 'Assistência odontológica':
      icon = 'fa-solid fa-tooth';
      break;
    case 'Assistência médica':
      icon = 'fa-solid fa-stethoscope';
      break;
    default:
      icon = 'fa-solid fa-heart-pulse';
      break;
  }

  return icon;
};

export const randomId = () => {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const createNameIcon = name => {
  if (name) {
    const fullName = name.split(' ');

    if (fullName.length === 1) {
      const initials = fullName[0].substring(0, 2);

      return initials.toUpperCase();
    } else {
      const initials = `${fullName.shift().charAt(0)}${fullName
        .pop()
        .charAt(0)}`;

      return initials.toUpperCase();
    }
  }

  return null;
};

export const getFileIcon = type => {
  let icons = {
    'application/pdf': 'fa-regular fa-file-pdf',
    'image/jpeg': 'fa-regular fa-file-image',
    'image/png': 'fa-regular fa-file-image',
    'image/gif': 'fa-regular fa-file-image',
    'text/csv': 'fa-solid fa-file-csv',
    'video/mp4': 'fa-solid fa-video',
    'application/vnd.ms-powerpoint': 'fa-regular fa-file-powerpoint',
    'application/vnd.ms-excel': 'fa-regular fa-file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'fa-regular fa-file-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'fa-regular fa-file-word',
    'application/x-zip-compressed': 'fa-regular fa-file-archive',
    default: 'fa-regular fa-file'
  };

  return icons[type] || icons['default'];
};

export const getFileColor = type => {
  let color = {
    'application/pdf': 'color--error',
    'image/jpeg': 'color--primary',
    'image/png': 'color--primary',
    'image/gif': 'color--primary',
    'text/csv': 'color--success',
    'video/mp4': 'color--orange',
    'application/msword': 'color--info',
    'application/vnd.ms-powerpoint': 'color--error',
    'application/vnd.ms-excel': 'color--success',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'color--success',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'color--info',
    'application/x-zip-compressed': 'color--warning',
    default: 'color--gray'
  };

  return color[type] || color['default'];
};

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};
