export const configureRequest = (api, params) => {
  let queryString = api;

  if (params) {
    const paramEntries = Object.entries(params).filter(([, value]) =>
      Boolean(value)
    );
    const paramStrings = paramEntries.map(([key, value]) => `${key}=${value}`);
    const paramString = paramStrings.join('&');
    queryString = `${queryString}?${paramString}`;
  }

  return queryString;
};

export const objectWithoutProperties = (obj, keys) => {
  const target = {};
  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      target[key] = obj[key];
    }
  });
  return target;
};
