import { showDesktopMessage } from '@/helpers/notification';
import { showMessage } from '@/helpers/messages';

const read = (state, payload) => {
  let index = state.findIndex(x => x.id === payload.id);

  if (index !== -1) {
    state[index].read = true;
  }
};

const notifyColor = item => {
  let color = {
    Sucesso: 'success',
    Aviso: 'warning',
    Critico: 'error',
    Info: 'info'
  };

  return color[item];
};

export default {
  openOrClosed: state => (state.drawer = !state.drawer),

  setNotifications: (state, payload) => {
    state.notifications = payload;
  },

  setMessage: (state, payload) => {
    if (payload.type === 'Aviso') {
      state.notifications.warningNotifications.unshift(payload);
    } else {
      state.notifications.taskNotifications.unshift(payload);
    }

    showDesktopMessage(payload);

    showMessage(notifyColor(payload.level), payload.title);
  },

  readNotification: (state, payload) => {
    if (payload.type === 'Aviso') {
      read(state.notifications.warningNotifications, payload);
    } else {
      read(state.notifications.taskNotifications, payload);
    }
  }
};
