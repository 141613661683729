<template>
  <v-text-field
    dense
    :id="id"
    :value="value"
    v-bind="$attrs"
    @blur="onBlur"
    @change="onChange"
  />
</template>

<script>
import AutoNumeric from 'autonumeric';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: Number,
      require: true
    },
    id: {
      type: String,
      require: true
    },
    options: {
      type: Object,
      require: false,
      default: () => {
        return {};
      }
    }
  },

  data: () => ({
    params: {
      decimalCharacter: ',',
      digitGroupSeparator: '.',
      watchExternalChanges: true,
      unformatOnHover: false
    }
  }),

  mounted() {
    new AutoNumeric(`#${this.id}`, { ...this.params, ...this.options });
  },

  methods: {
    onBlur() {
      let val = AutoNumeric.getNumber(document.getElementById(this.id));

      this.$emit('input', val);
    },

    onChange() {
      let val = AutoNumeric.getNumber(document.getElementById(this.id));

      this.$emit('change', val);
    }
  }
};
</script>
