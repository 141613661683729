import { objectWithoutProperties } from '@/helpers/configureRequest';
import { HttpClient } from '@/services/http';

export class ContractService extends HttpClient {
  async getContract(params) {
    const result = await this.get('v1/contracts', params);
    return result;
  }

  async createContract(params) {
    const result = await this.post('v1/contracts', params);
    return result;
  }

  async updateContract(params) {
    const result = await this.put('v1/contracts', params);
    return result;
  }

  async getContractById(params) {
    const result = await this.get(`v1/contracts/${params.id}`);
    return result;
  }

  async getContractInfo(params) {
    const result = await this.get(`v1/contracts/${params.id}/info`);
    return result;
  }

  async cancel(params) {
    const result = await this.post(
      `v1/contracts/${params.id}/cancellation`,
      params
    );
    return result;
  }

  async getCoveragesByContractIdByCustomerId(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/coverages`,
      newParams
    );

    return result;
  }

  async getCoveragesByContractId(contractId) {
    const result = await this.get(`v1/contracts/${contractId}/coverages/list`);
    return result;
  }

  async createCoverage(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/coverage`,
      params
    );
    return result;
  }

  async updateCoverage(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/coverage`,
      params
    );
    return result;
  }

  async deleteCoverage(params) {
    const result = await this.delete(`v1/contracts/coverage/${params.id}`);
    return result;
  }

  async getDocuments(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/documents`,
      newParams
    );

    return result;
  }

  async deleteDocument(params) {
    const result = await this.delete(`v1/contracts/documents/${params.id}`);
    return result;
  }

  async uploadDocument(params) {
    let form = new FormData();

    form.append('file', params.file);

    const result = await this.post(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/documents/upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async downloadDocument(params) {
    const result = await this.get(
      `v1/contracts/documents/${params.id}/download`
    );
    return result;
  }

  async getAnnotation(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/annotation`
    );
    return result;
  }

  async createAnnotation(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/annotation`,
      params
    );

    return result;
  }

  async updateAnnotation(params) {
    const result = await this.put(`v1/contracts/annotation`, params);
    return result;
  }

  async getCustomersAvailable(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/customers/available-customers`
    );
    return result;
  }

  async getCustomers(params) {
    const newParams = objectWithoutProperties(params, ['contractId']);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customers`,
      newParams
    );

    return result;
  }

  async createCustomers(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/customers`,
      params
    );
    return result;
  }

  async updateCustomers(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/customers`,
      params
    );
    return result;
  }

  async deleteCustomers(params) {
    const result = await this.delete(`v1/contracts/customers/${params.id}`);
    return result;
  }

  async getLifeCapital(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/lifeCapital`
    );
    return result;
  }

  async createLifeCapital(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/lifeCapital`,
      params
    );

    return result;
  }

  async updateLifeCapital(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/lifeCapital`,
      params
    );
    return result;
  }

  async deleteLifeCapital(id) {
    const result = await this.delete(`v1/contracts/lifeCapital/${id}`);
    return result;
  }

  async getLeaveBeneficiariesCount(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/leave-beneficiaries/count`
    );
    return result;
  }

  async getLeaveBeneficiaries(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/leave-beneficiaries`,
      newParams
    );

    return result;
  }

  async createLeaveBeneficiaries(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/leave-beneficiaries`,
      params
    );

    return result;
  }

  async updateLeaveBeneficiaries(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/leave-beneficiaries`,
      params
    );
    return result;
  }

  async deleteLeaveBeneficiaries(id) {
    const result = await this.delete(`v1/contracts/leave-beneficiaries/${id}`);
    return result;
  }

  async getPlansAvailable(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/plans/available`
    );
    return result;
  }

  async getPlans(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/plans`,
      newParams
    );

    return result;
  }

  async createPlan(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/plans`,
      params
    );
    return result;
  }

  async updatePlan(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/plans`,
      params
    );
    return result;
  }

  async deletePlan(id) {
    const result = await this.delete(`v1/contracts/plans/${id}`);
    return result;
  }

  async readjustmentPlan(params) {
    const result = await this.put(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/plans/readjustment`,
      params
    );
    return result;
  }

  async readjustmentPlanHistory(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/plans/readjustment/history`,
      newParams
    );

    return result;
  }

  async getContractAccessData(params) {
    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/access-data`
    );
    return result;
  }

  async postContractAccessData(params) {
    const result = await this.post(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/access-data`,
      params
    );
    return result;
  }

  async getCoverageReadjustments(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/coverages/readjustment-history`,
      newParams
    );

    return result;
  }

  async getCoverageTax(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/coverages/readjustment-tax-history`,
      newParams
    );

    return result;
  }

  async updateContractCoverageTax(params) {
    const result = await this.put(`v1/contracts/coverage/readjust-tax`, params);
    return result;
  }

  async getLifeCapitalReadjustmentHistories(params) {
    const newParams = objectWithoutProperties(params, [
      'contractId',
      'customerId'
    ]);

    const result = await this.get(
      `v1/contracts/${params.contractId}/customer/${params.customerId}/life-capital/readjustment/history`,
      newParams
    );

    return result;
  }
}
