const routes = [
  {
    path: '/indexSupport',
    meta: {
      public: false,
      title: 'Atendimento'
    },
    redirect: '/suportes',
    component: () => import('@/views/Support/Index.vue'),
    children: [
      {
        path: '/suportes',
        meta: {
          public: false,
          title: 'Atendimento'
        },
        name: 'suportes',
        component: () => import('@/views/Support/List.vue')
      },
      {
        path: '/suportes/edit/:id?/:ticketId?',
        meta: {
          public: false,
          title: 'Atendimento - Consulta de chamado'
        },
        name: 'suportes-edit',
        component: () => import('@/views/Support/Edit.vue')
      },
      {
        path: '/suportes/new',
        meta: {
          public: false,
          title: 'Atendimento - Abertura de chamado'
        },
        name: 'suportes-new',
        component: () => import('@/views/Support/New.vue')
      }
    ]
  }
];

export default routes;
