import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/indexInsuranceCompany',
    meta: {
      public: false,
      title: 'Operadoras e seguradoras'
    },
    redirect: '/operadoras',
    component: () =>
      import('@/views/BrokerManagement/InsuranceCompany/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2', 'C3', 'CC1', 'CC2'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    },
    children: [
      {
        path: '/operadoras',
        meta: {
          public: false,
          title: 'Operadoras e seguradoras'
        },
        name: 'operadoras',
        component: () =>
          import('@/views/BrokerManagement/InsuranceCompany/List.vue')
      },
      {
        path: '/operadoras/create-or-edit/:id?/:cnpj?',
        meta: {
          public: false,
          title: 'Visualizar operadora e/ou seguradora'
        },
        name: 'operadoras-edit',
        component: () =>
          import('@/views/BrokerManagement/InsuranceCompany/Edit.vue')
      }
    ]
  }
];

export default routes;
