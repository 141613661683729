import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import { authentication } from '@/store/modules/authentication';
import { loading } from '@/store/modules/loading';
import { contracts } from '@/store/modules/contracts';
import { movements } from '@/store/modules/movements';
import { invoice } from '@/store/modules/invoice';
import { notification } from '@/store/modules/notification';
import { support } from '@/store/modules/support';

const vuexPersist = new VuexPersist({
  key: 'application',
  storage: sessionStorage,
  reducer: state => ({
    authentication: state.authentication
  })
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    authentication,
    loading,
    contracts,
    movements,
    invoice,
    notification,
    support
  }
});
