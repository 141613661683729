import { HttpClient } from '@/services/http';

export class BusinessUnitService extends HttpClient {
  async getBusinessUnit(params) {
    const result = await this.get('v1/businessUnit', params);
    return result;
  }

  async postBusinessUnit(params) {
    const result = await this.post('v1/businessUnit', params);
    return result;
  }

  async putBusinessUnit(params) {
    const result = await this.put('v1/businessUnit', params);
    return result;
  }

  async getById(id) {
    const result = await this.get(`v1/businessUnit/${id}`);
    return result;
  }

  async allBusinessUnits() {
    const result = await this.get('v1/businessUnit/all');
    return result;
  }
}
