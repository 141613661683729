import { showMessage } from '@/helpers/messages';

const showNotification = item => {
  const notification = new Notification('Biobe informa!', {
    body: item.title,
    icon: 'https://biobe.com.br/icon.png'
  });

  if (item.url) {
    notification.onclick = () => {
      window.location.href = item.url;
    };
  }
};

export const showDesktopMessage = item => {
  if (!('Notification' in window)) {
    showMessage(
      'warning',
      'Este navegador não suporta notificação na área de trabalho'
    );
  } else if (Notification.permission === 'granted') {
    showNotification(item);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (!('permission' in Notification)) {
        Notification.permission = permission;
      }

      if (permission === 'granted') {
        showNotification(item);
      }
    });
  } else {
    showMessage(
      'warning',
      'A permissão para receber as notificações do sistema estão bloqueadas, caso deseje receber, altere as configurações do seu navegador. Obrigado!'
    );
  }
};
