import { HttpClient } from '@/services/http';
import { objectWithoutProperties } from '@/helpers/configureRequest';

export class InsuranceCompanyService extends HttpClient {
  async getInsurances(params) {
    const result = await this.get('v1/insurance-companies', params);
    return result;
  }

  async postInsurance(params) {
    const newParams = objectWithoutProperties(params, ['logo64']);
    const result = await this.post('v1/insurance-companies', newParams);
    return result;
  }

  async putInsurance(params) {
    const newParams = objectWithoutProperties(params, ['logo64']);
    const result = await this.put('v1/insurance-companies', newParams);
    return result;
  }

  async getById(id) {
    const result = await this.get(`v1/insurance-companies/${id}`);
    return result;
  }

  async uploadLogo(params) {
    let form = new FormData();
    form.append('file', params.logo64);

    const result = await this.post(
      `v1/insurance-companies/${params.document}/upload-logo`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return result;
  }

  async getContacts(params) {
    const newParams = objectWithoutProperties(params, [
      'insuranceCompanyDocument'
    ]);

    const result = await this.get(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/contacts`,
      newParams
    );
    return result;
  }

  async deleteContact(params) {
    const result = await this.delete(
      `v1/insurance-companies/${params.document}/contacts/${params.id}`
    );
    return result;
  }

  async createContact(params) {
    const result = await this.post(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/contacts`,
      params
    );
    return result;
  }

  async updateContact(params) {
    const result = await this.put(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/contacts`,
      params
    );
    return result;
  }

  async getPlans(params) {
    const newParams = objectWithoutProperties(params, [
      'insuranceCompanyDocument'
    ]);

    const result = await this.get(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/plans`,
      newParams
    );
    return result;
  }

  async getPlanById(params) {
    const result = await this.get(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/plans/${params.id}`
    );
    return result;
  }

  async deletePlans(params) {
    const result = await this.delete(
      `v1/insurance-companies/${params.document}/plans/${params.id}`
    );
    return result;
  }

  async createPlans(params) {
    const result = await this.post(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/plans`,
      params
    );
    return result;
  }

  async updatePlans(params) {
    const result = await this.put(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/plans`,
      params
    );
    return result;
  }

  async getDocuments(params) {
    const newParams = objectWithoutProperties(params, [
      'insuranceCompanyDocument'
    ]);

    const result = await this.get(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/documents`,
      newParams
    );

    return result;
  }

  async createDocument(params) {
    params.documentPath = params.file.name;

    const result = await this.post(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/documents`,
      params
    );

    return result;
  }

  async deleteDocument(params) {
    const result = await this.delete(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/documents/${params.id}`
    );
    return result;
  }

  async uploadDocument(params) {
    let form = new FormData();
    form.append('file', params.file);

    const result = await this.post(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/documents/upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return result;
  }

  async downloadDocument(params) {
    const result = await this.get(
      `v1/insurance-companies/${params.insuranceCompanyDocument}/documents/${params.id}/download`
    );
    return result;
  }
}
