import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

import '@/components/base';
import '@/directives';
import '@/plugins/library';
import '@/plugins/styles';

Vue.config.productionTip = false;

export default new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
