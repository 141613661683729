<template>
  <v-sheet outlined class="b-expansion-code">
    <div class="b-expansion-code__content">
      <div class="b-expansion-code__content--buttons">
        <BaseButton
          text
          title="Enviado"
          :outlined="step === 1"
          :color="step === 1 ? 'primary' : ''"
          @click="step = 1"
        />
        <BaseButton
          text
          title="Recebido"
          :outlined="step === 2"
          :color="step === 2 ? 'primary' : ''"
          @click="step = 2"
        />
      </div>

      <v-window v-model="step" class="b-expansion-code__content--step">
        <v-window-item :value="1">
          <prism id="json-send" language="javascript">
            {{ detail.requestObject }}
          </prism>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="b-expansion-code__content--copy"
                @click="copy('json-send')"
              >
                <v-fade-transition hide-on-leave>
                  <v-icon :key="String(clicked)" color="grey">
                    {{ clicked ? 'fa-solid fa-copy' : 'fa-regular fa-copy' }}
                  </v-icon>
                </v-fade-transition>
              </v-btn>
            </template>
            <span>{{ clicked ? 'Copiado' : 'Copiar' }}</span>
          </v-tooltip>
        </v-window-item>
        <v-window-item :value="2">
          <prism id="json-received" language="javascript">
            {{ detail.responseObject }}
          </prism>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="b-expansion-code__content--copy"
                @click="copy('json-received')"
              >
                <v-fade-transition hide-on-leave>
                  <v-icon :key="String(clicked)" color="grey">
                    {{ clicked ? 'fa-solid fa-copy' : 'fa-regular fa-copy' }}
                  </v-icon>
                </v-fade-transition>
              </v-btn>
            </template>
            <span>{{ clicked ? 'Copiado' : 'Copiar' }}</span>
          </v-tooltip>
        </v-window-item>
      </v-window>
    </div>
  </v-sheet>
</template>

<script>
import { wait } from '@/helpers/utils';
import Prism from 'vue-prism-component';

export default {
  components: {
    Prism
  },

  props: {
    detail: {
      type: Object,
      require: true
    }
  },

  data: () => ({
    clicked: false,
    wait: 2000,
    step: 1
  }),

  methods: {
    async copy(element) {
      const el = document.getElementById(element);

      el.setAttribute('contenteditable', 'true');
      el.focus();

      document.execCommand('selectAll', false, null);
      document.execCommand('copy');

      el.removeAttribute('contenteditable');

      this.clicked = true;

      await wait(this.wait);

      this.clicked = false;
    }
  }
};
</script>
