export default {
  setMe: (state, payload) => {
    let profile = Object.assign({}, payload);
    state.menus = payload.menus;

    delete profile.menus;
    state.profile = profile;
  },

  setToken: (state, payload) => {
    state.token = payload;
  },

  clear: state => {
    state.profile = null;
    state.token = null;
    state.menus = [];
  },

  acceptPrivacyTerm: state => {
    state.acceptTerm = false;
  },

  acceptedPrivacyTerm: (state, payload) => {
    state.acceptTerm = payload.acceptedPrivacyTerm ? false : true;
  }
};
