import store from '@/store';
import router from '@/router';
const TOKEN = 'biobe_token';

export const setToken = token => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  const tokenStore = store.getters['authentication/getToken'];
  const tokenStorage = localStorage.getItem(TOKEN);

  if (tokenStore) {
    return tokenStore;
  } else if (tokenStorage) {
    store.commit('authentication/setToken', tokenStorage);
    return tokenStorage;
  } else {
    return null;
  }
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN);
  store.commit('authentication/clear');
  router.push({ name: 'login' });
};
