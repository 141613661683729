import getters from './getters';
import mutations from './mutations';

export const notification = {
  namespaced: true,
  state: {
    drawer: false,
    notifications: {
      taskNotifications: [],
      warningNotifications: []
    }
  },
  getters,
  mutations
};
