import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/indexImplementation',
    meta: {
      public: false,
      title: 'Implantações'
    },
    redirect: '/implantacoes',
    component: () =>
      import('@/views/OperationalManagement/Implementation/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2', 'C3', 'F1', 'F2', 'F3'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    },
    children: [
      {
        path: '/implantacoes',
        meta: {
          public: false,
          title: 'Implantações'
        },
        name: 'implantacoes',
        component: () =>
          import('@/views/OperationalManagement/Implementation/List.vue')
      },
      {
        path: '/implantacoes/edit/:id?/:situation?',
        meta: {
          public: false,
          title: 'Visualizar implantação'
        },
        name: 'implantacoes-edit',
        component: () =>
          import('@/views/OperationalManagement/Implementation/Edit.vue')
      },
      {
        path: '/implantacoes/new',
        meta: {
          public: false,
          title: 'Iniciar uma nova implantação'
        },
        name: 'implantacoes-new',
        component: () =>
          import('@/views/OperationalManagement/Implementation/New.vue')
      }
    ]
  }
];

export default routes;
