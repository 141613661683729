export default {
  startLoading: (state, payload) => {
    state.isLoading = payload;

    state.openRequisitions++;
  },

  finishLoading: (state, payload) => {
    state.openRequisitions--;

    if (payload && payload.verificationCount) {
      if (state.openRequisitions <= 0) {
        state.isLoading = false;
        state.openRequisitions = 0;
      }
    } else {
      state.isLoading = false;
      state.openRequisitions = 0;
    }
  }
};
