import { HttpClient } from '@/services/http';
import {
  configureRequest,
  objectWithoutProperties
} from '@/helpers/configureRequest';

export class MovementService extends HttpClient {
  async getMovements(params) {
    const newParams = objectWithoutProperties(params, ['customerId']);

    const result = await this.get(
      `v1/movements/customers/${params.customerId}/employees`,
      newParams
    );

    return result;
  }

  async getEmployeeById({ employeeId }) {
    const result = await this.get(`v1/movements/employees/${employeeId}`);
    return result;
  }

  async getMovementsInfo({ employeeId }) {
    const result = await this.get(`v1/movements/employees/${employeeId}/info`);
    return result;
  }

  async create(params) {
    const result = await this.post(
      `v1/movements/customers/${params.customerId}/employees`,
      params
    );

    return result;
  }

  async update(params) {
    const result = await this.put(
      `v1/movements/customers/${params.customerId}/employees`,
      params
    );

    return result;
  }

  async getAvailableContracts(params) {
    let querystring;

    if (params.productType) {
      let newParams = objectWithoutProperties(params, ['customerId']);

      querystring = configureRequest(
        `v1/movements/customers/${params.customerId}/available-contracts`,
        newParams
      );
    } else {
      querystring = `v1/movements/customers/${params.customerId}/available-contracts`;
    }

    const result = await this.get(querystring);
    return result;
  }

  async getBanks(params) {
    const result = await this.get('v1/movements/available-banks', params);
    return result;
  }

  async getAvailableEmployeeContracts(employeeId) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/available-contracts`
    );
    return result;
  }

  async getDependents(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/dependents`,
      newParams
    );

    return result;
  }

  async getDependentById({ employeeId, dependentId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/dependents/${dependentId}`
    );
    return result;
  }

  async updateDependent(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/dependents`,
      params
    );

    return result;
  }

  async createDependent(params) {
    const newParams = objectWithoutProperties(params, ['id', 'employeeId']);

    const result = await this.post(
      `v1/movements/employees/${params.employeeId}/dependents`,
      newParams
    );

    return result;
  }

  async getAvailableMovementPlans(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/plans/available-plans`,
      newParams
    );

    return result;
  }

  async saveEmployeePlan(params) {
    const result = await this.post(
      `v1/movements/employees/${params.employeeId}/plans`,
      params
    );

    return result;
  }

  async getEmployeePlan(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/plans`,
      newParams
    );

    return result;
  }

  async getEmployeeAndDependentPlanValue(params) {
    const newParams = objectWithoutProperties(params, ['employeeId', 'planId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/plans/${params.planId}/values`,
      newParams
    );

    return result;
  }

  async uploadEmployeeDocument(params) {
    const newParams = objectWithoutProperties(params, [
      'file',
      'employeeId',
      'employeeOrDependentId',
      'contractId'
    ]);

    const querystring = configureRequest(
      `v1/movements/employees/${params.employeeId}/documents/employee-or-dependent/${params.employeeOrDependentId}/upload`,
      newParams
    );

    let form = new FormData();
    const { file, contractId } = params;

    form.append('file', file);
    form.append('contractId', contractId);

    const result = await this.post(querystring, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return result;
  }

  async getAvailableMovementDocumentLives({ employeeId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/documents/available-lives`
    );

    return result;
  }

  async getPagedEmployeeDocuments(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/documents`,
      newParams
    );

    return result;
  }

  async deleteDocument(params) {
    const result = await this.delete(
      `v1/movements/employees/${params.employeeId}/documents/${params.id}`
    );

    return result;
  }

  async downloadDocument(params) {
    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/documents/${params.id}/download`
    );

    return result;
  }

  async getEmployeeBillingCards(employeeId) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/billing/resume`
    );

    return result;
  }

  async getEmployeeBilling(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/billing`,
      newParams
    );

    return result;
  }

  async putEmployeeBilling(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/billing`,
      params
    );

    return result;
  }

  async getMovementHeader({ employeeId, employeeOrDependentId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/employee-or-dependent/${employeeOrDependentId}/header`
    );

    return result;
  }

  async getChangeAgeGroup(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/change-age`,
      newParams
    );

    return result;
  }

  async changeAgeGroup(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/change-age`,
      params
    );

    return result;
  }

  async getMovementRemission(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/remission`,
      newParams
    );

    return result;
  }

  async saveMovementRemission(params) {
    const result = await this.post(
      `v1/movements/employees/${params.employeeId}/remission`,
      params
    );

    return result;
  }

  async getTransferBetweenCustomers(params) {
    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/transfer-customer`
    );

    return result;
  }

  async getAvailableTransferContracts(params) {
    const newParams = objectWithoutProperties(params, ['customerId']);

    const result = await this.get(
      `v1/movements/customers/${params.customerId}/available-transfer-contracts`,
      newParams
    );

    return result;
  }

  async getAvailablePlanTransfer({ employeeId, contractId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/contrats/${contractId}/available-transfer-plans`
    );

    return result;
  }

  async putTransferBetweenCustomers(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/transfer-customer`,
      params
    );

    return result;
  }

  async getPlanChangeMovement(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/plan-change`,
      newParams
    );

    return result;
  }

  async getMovementPlanChangePlan({ employeeId, contractId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/contracts/${contractId}/plan-change`
    );

    return result;
  }

  async putMovementPlanChange(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/plan-change`,
      params.model
    );

    return result;
  }

  async getMovementRetirement(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/retirement`,
      newParams
    );

    return result;
  }

  async putMovementRetirement(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/retirement`,
      params.model
    );

    return result;
  }

  async getMovementResignation(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/resignation`,
      newParams
    );

    return result;
  }

  async putMovementResignation(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/resignation`,
      params.model
    );

    return result;
  }

  async getMovementExclusion(params) {
    const newParams = objectWithoutProperties(params, [
      'employeeId',
      'productType'
    ]);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/exclusion`,
      newParams
    );

    return result;
  }

  async deleteMovementExclusion(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.delete(
      `v1/movements/employees/${params.employeeId}/exclusion`,
      newParams
    );

    return result;
  }

  async getPagedEmployeeHistories(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/histories`,
      newParams
    );

    return result;
  }

  async getAvailableDependentsContracts({ employeeId, dependentId }) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/dependents/${dependentId}/available-contracts`
    );

    return result;
  }

  async getMovementExclusionDependent(params) {
    const newParams = objectWithoutProperties(params, [
      'employeeId',
      'dependentId',
      'productType'
    ]);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/dependents/${params.dependentId}/exclusion`,
      newParams
    );

    return result;
  }

  async deleteMovementExclusionDependent(params) {
    const newParams = objectWithoutProperties(params, [
      'employeeId',
      'dependentId'
    ]);

    const result = await this.delete(
      `v1/movements/employees/${params.employeeId}/dependents/${params.dependentId}/exclusion`,
      newParams
    );

    return result;
  }

  async getAvailableDependents(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/available-dependents`,
      newParams
    );

    return result;
  }

  async saveLinkDependent(params) {
    const result = await this.post(
      `v1/movements/employees/${params.employeeId}/link-dependents`,
      params
    );

    return result;
  }

  async getDetachedContracts(params) {
    const newParams = objectWithoutProperties(params, ['employeeId']);

    const result = await this.get(
      `v1/movements/employees/${params.employeeId}/detached-contracts`,
      newParams
    );

    return result;
  }

  async putBindContract(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/bind-contracts`,
      params
    );

    return result;
  }

  async getContractsForReactivation(employeeId) {
    const result = await this.get(
      `v1/movements/employees/${employeeId}/reactivation`
    );

    return result;
  }

  async putContractsForReactivation(params) {
    const result = await this.put(
      `v1/movements/employees/${params.employeeId}/reactivation`,
      params.contracts
    );

    return result;
  }
}
