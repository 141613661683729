import { HttpClient } from '@/services/http';

export class PartnerService extends HttpClient {
  async getAvailablePartners(userId) {
    const result = await this.get(`v1/partners/available/user/${userId}`);
    return result;
  }

  async getPartnersUser(userId) {
    const result = await this.get(`v1/partners/user/${userId}`);
    return result;
  }

  async getPartnerUser(partnerId, userId) {
    const result = await this.get(`v1/partners/${partnerId}/user/${userId}`);
    return result;
  }

  async postPartnerUser(params) {
    const result = await this.post(`v1/partners/user`, params);
    return result;
  }

  async putPartnerUser(params) {
    const result = await this.put(`v1/partners/user`, params);
    return result;
  }
}
