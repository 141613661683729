<template>
  <v-autocomplete
    dense
    outlined
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, null],
      require: true
    }
  },

  data: () => ({
    items: [
      'Seguro novo',
      'Renovação própria corretora',
      'Transferência de corretagem'
    ]
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
