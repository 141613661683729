import { AccountService } from '@/services/api/account';

export const meMixin = {
  methods: {
    async getMe() {
      try {
        const accountService = new AccountService();
        const { status, data } = await accountService.me();

        if (status === 200) {
          this.$store.commit('authentication/setMe', data);
          this.$store.commit('authentication/acceptedPrivacyTerm', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
