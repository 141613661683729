import getters from './getters';
import mutations from './mutations';

const contractTabs = [
  {
    href: 'tab-1',
    title: 'Contrato',
    icon: 'fa-regular fa-file-lines',
    component: 'PageContract',
    visible: true,
    disabled: false
  },
  {
    href: 'tab-2',
    title: 'Subestipulantes',
    icon: 'fa-solid fa-users',
    component: 'PageCustomers',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-3',
    title: 'Coberturas',
    icon: 'fa-solid fa-user-shield',
    component: 'PageCoverage',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-4',
    title: 'Capital',
    icon: 'fa-solid fa-money-bill-1',
    component: 'PageCapital',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-5',
    title: 'Planos',
    icon: 'fa-solid fa-file-waveform',
    component: 'PagePlans',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-6',
    title: 'Documentos',
    icon: 'fa-regular fa-folder',
    component: 'PageDocuments',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-7',
    title: 'Particularidades',
    icon: 'fa-solid fa-book',
    component: 'PageParticularities',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-8',
    title: 'Beneficiários Afastados',
    icon: 'fa-solid fa-user-slash',
    component: 'PageLeaveBeneficiary',
    visible: true,
    disabled: true
  },
  {
    href: 'tab-9',
    title: 'Dados de acesso',
    icon: 'fa-solid fa-right-to-bracket',
    component: 'PageAccessData',
    visible: true,
    disabled: true
  }
];

export const contracts = {
  namespaced: true,
  state: {
    contractTabs,
    contractById: {}
  },
  getters,
  mutations
};
