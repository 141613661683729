import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import localePt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
  pt: {
    $vuetify: localePt
  }
};

const i18n = new VueI18n({
  locale: 'pt',
  messages
});

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'md'
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#5C036F',
        secondary: '#BCC300',
        error: '#fc4b6c',
        warning: '#ffb22b',
        success: '#35AB00'
      },
      dark: {
        primary: '#BCC300',
        secondary: '#5C036F',
        error: '#fc4b6c',
        warning: '#ffb22b',
        success: '#35AB00'
      }
    }
  }
});
