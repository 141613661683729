<template>
  <v-text-field
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
    :value="value"
    v-bind="$attrs"
    @input="onInput"
    @click:append="show = !show"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    }
  },

  data: () => ({
    show: false
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event);
    }
  }
};
</script>
