import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueTheMask from 'vue-the-mask';
import VuetifyMoney from 'vuetify-money';
import VueApexCharts from 'vue-apexcharts';
import VueToast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import 'prismjs';
import 'prismjs/themes/prism.css';

Vue.use(VueReCaptcha, {
  siteKey: '6LfGCZscAAAAANQjwn4bRHkGxp7FLIkS-QOh_31_',
  loaderOptions: {
    autoHideBadge: true
  }
});

Vue.use(VueToast);
Vue.use(VueTheMask);
Vue.use(VuetifyMoney);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
