export default {
  setEmployee: (state, payload) => {
    state.employee = payload;
  },

  createTabs: (state, payload) => {
    if (payload && (payload.id || payload.employeeId)) {
      state.tabs.forEach(x => (x.disabled = false));
    } else {
      state.tabs.forEach(x => (x.disabled = true));
      state.tabs[0].disabled = false;
    }
  },

  setMovementHeader: (state, payload) => {
    if (payload) {
      state.employeeHeader = payload;

      if (payload.kinship === 'Titular') {
        state.cardsFull.forEach(x => (x.visible = true));
        state.cardsFull[3].visible = payload.hasRemission;
        state.cardsFull[4].visible = payload.isContributory;
        state.cardsFull[5].visible = payload.isContributory;
        state.cardsFull[6].visible = payload.hasSub;

        if (payload.hasSub) {
          state.cardsFull[6].visible = true;
        } else {
          state.cardsFull[6].visible = false;
        }

        if (payload.situation === 'Aposentado') {
          state.cardsFull.forEach(x => (x.visible = false));
          state.cardsFull[0].visible = true;
          state.cardsFull[4].visible = true;
        } else if (payload.situation === 'Inativo com vínculo') {
          state.cardsFull.forEach(x => (x.visible = false));
          state.cardsFull[0].visible = true;
          state.cardsFull[5].visible = true;
        } else if (payload.situation === 'Inativo') {
          state.cardsFull.forEach(x => (x.visible = false));
          state.cardsFull[0].visible = true;
        } else if (payload.situation === 'Em remissão') {
          state.cardsFull.forEach(x => (x.visible = false));
          state.cardsFull[0].visible = true;
          state.cardsFull[3].visible = true;
        }
      } else {
        state.cardsFull.forEach(x => (x.visible = false));
        state.cardsFull[0].visible = true;
        state.cardsFull[1].visible = true;
      }

      state.cards = state.cardsFull.filter(x => x.visible);
    } else {
      state.employeeHeader = {};
    }
  },

  setCustomerName: (state, payload) => {
    state.customerName = payload.length > 0 ? payload[0].customerName : null;
  },

  clearCardPage: state => {
    state.employee = {};
    state.employeeHeader = {};
    state.customerName = null;
    state.cards = [];
    state.cardsFull.forEach(x => (x.visible = false));
  }
};
