const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: {
      path: '/dashboard'
    }
  },
  {
    path: '/dashboard',
    meta: {
      public: false,
      title: 'Início'
    },
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: (to, from, next) => {
      if (from.query && from.query.returnUrl) {
        return next({ path: from.query.returnUrl });
      }

      next();
    }
  }
];

export default routes;
