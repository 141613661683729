import getters from './getters';
import mutations from './mutations';

export const authentication = {
  namespaced: true,
  state: {
    acceptTerm: false,
    menus: [],
    profile: null,
    token: null
  },
  getters,
  mutations
};
