const routes = [
  {
    path: '/indexReport',
    redirect: '/relatorios',
    meta: {
      public: false,
      title: 'Relatórios'
    },
    component: () => import('@/views/Report/Index.vue'),
    children: [
      {
        path: '/relatorios',
        meta: {
          public: false,
          title: 'Relatórios'
        },
        name: 'relatorios',
        component: () => import('@/views/Report/List.vue')
      },
      {
        path: '/relatorios/contratos',
        meta: {
          public: false,
          title: 'Relatório de contratos'
        },
        name: 'relatorio-contrato',
        component: () => import('@/views/Report/Contracts/Index.vue')
      },
      {
        path: '/relatorios/faturas',
        meta: {
          public: false,
          title: 'Relatório de faturas'
        },
        name: 'relatorio-faturas',
        component: () => import('@/views/Report/Invoice/Index.vue')
      }
    ]
  }
];

export default routes;
