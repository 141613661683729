import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { showMessage } from '@/helpers/messages';
import { checkApi } from '@/helpers/utils';
import { getToken, removeToken } from '@/helpers/token';

export class HttpClient {
  constructor(loading = true) {
    this.loading = loading;
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_URL}/`
    });

    this.enableInterceptors();
  }

  enableInterceptors() {
    const isLoading = this.loading;

    this.axiosInstance.interceptors.request.use(
      function (config) {
        if (isLoading) {
          store.dispatch('loading/startLoading');
        }

        const token = getToken();
        const valid = checkApi(config.url);

        if (token && !valid) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      function (error) {
        if (error?.response) {
          const { errors } = error?.response.data;
          showMessage('error', errors);
        }
        store.dispatch('loading/finishLoading');
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      function (response) {
        store.dispatch('loading/finishLoading', {
          verificationCount: true
        });
        return response;
      },
      function (error) {
        if (error?.response) {
          const response = error.response;
          const errors = response.data ? response.data.errors : null;
          const { status, statusText } = response;
          const errorMessage = errors || `${status} - ${statusText}`;

          if (status === 0 || status === 401) {
            removeToken();
          }

          if (status === 403) {
            router.push({ path: '/403' });
          }

          if (status === 404) {
            router.push({ path: '/404' });
          }

          if (errors || statusText) {
            showMessage('error', errorMessage);
          }
        }

        store.dispatch('loading/finishLoading');

        return Promise.reject(error);
      }
    );
  }

  get(url, params) {
    return this.axiosInstance.get(url, { params });
  }

  post(url, params, headers) {
    return this.axiosInstance.post(url, params, headers);
  }

  put(url, params, headers) {
    return this.axiosInstance.put(url, params, headers);
  }

  delete(url, params, headers) {
    return this.axiosInstance.delete(url, { data: params }, headers);
  }
}
