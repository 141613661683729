import contracts from './contracts';
import customers from './customers';
import implementation from './implementation';
import invoice from './invoice';
import movements from './movements';
import integration from './integration';

const routes = [
  ...contracts,
  ...customers,
  ...implementation,
  ...integration,
  ...invoice,
  ...movements
];

export default routes;
