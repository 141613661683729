import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/helpers/token';

import accessControl from './accessControl';
import account from './account';
import brokerManagement from './brokerManagement';
import dashboard from './dashboard';
import operationalManagement from './operationalManagement';
import publicRoutes from './public';
import report from './report';
import support from './support';

Vue.use(VueRouter);

const routes = [
  ...accessControl,
  ...account,
  ...brokerManagement,
  ...dashboard,
  ...operationalManagement,
  ...publicRoutes,
  ...report,
  ...support
];

const router = new VueRouter({
  base: '/',
  mode: 'hash',
  routes
});

router.beforeEach(function (to, from, next) {
  const isPublicRoute = to.matched.some(route => route.meta.public);

  const token = getToken();

  if (!isPublicRoute && !token) {
    const path = to.name === 'dashboard';

    return next({
      path: '/login',
      query: path ? {} : { returnUrl: to.fullPath }
    });
  }

  if (to.path === '/login' && token) {
    return next({ path: from.path });
  }

  if (to.meta.title) {
    document.title = `Biobe - ${to.meta.title}`;
  }

  next();
});

export default router;
