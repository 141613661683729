<template>
  <v-text-field dense outlined :value="value" v-bind="$attrs" @input="onInput">
    <template v-slot:append>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="open" color="primary" class="ansOpen">
            fa-solid fa-circle-info
          </v-icon>
        </template>
        <span>Deseja verificar o registro ANS? Clique aqui</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    }
  },

  methods: {
    open() {
      window.open('http://www.ans.gov.br/ConsultaPlanosConsumidor/', '_blank');
    },

    onInput(event) {
      this.$emit('input', event);
    }
  }
};
</script>
