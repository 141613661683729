<template>
  <v-autocomplete
    dense
    outlined
    item-text="businessPlanName"
    item-value="id"
    :loading="isLoading"
    :return-object="returnObject"
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
import { ContractService } from '@/services/api/contracts';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, Object],
      require: true
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    contractId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    items: []
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.isLoading = true;

      try {
        const service = new ContractService(false);
        const { status, data } = await service.getPlansAvailable({
          contractId: this.contractId
        });

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
