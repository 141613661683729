<template>
  <v-autocomplete
    dense
    outlined
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, null],
      require: true
    }
  },

  data: () => ({
    items: [
      'RG',
      'CPF',
      'Carta de cancelamento',
      'Carta de concessão do INSS',
      'Certidão de casamento',
      'Certidão de nascimento',
      'Comprovante de residência',
      'Contrato de trabalho',
      'Carteira de trabalho',
      'Formulário de cancelamento',
      'Proposta',
      'Rescisão contratual',
      'Termo de rescisão',
      'Outros'
    ]
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
