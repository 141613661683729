import { HttpClient } from '@/services/http';

export class AccountService extends HttpClient {
  async me() {
    const result = await this.get('v1/me');
    return result;
  }

  async forgotPassword(params) {
    const result = await this.post('v1/account/forgot-password', params);
    return result;
  }

  async changePassword(params) {
    const result = await this.put('v1/account/change-password', params);
    return result;
  }

  async resetPassword(params) {
    const result = await this.put('v1/account/reset-password', params);
    return result;
  }

  async activate(params) {
    const result = await this.post('v1/account/activate', params);
    return result;
  }

  async logout() {
    const result = await this.post('v1/account/logout');
    return result;
  }

  async acceptPrivacyTerm(params) {
    const result = await this.post('v1/account/accept-privacy-term', params);
    return result;
  }

  async getMarkdown() {
    const result = await this.get(
      `${window.location.origin}${window.location.pathname}static/md/accept-term.md`
    );
    return result;
  }
}
