<template>
  <v-autocomplete
    dense
    outlined
    :items="filteredItems"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, null],
      require: true
    },
    registeredStatus: {
      type: [String, null]
    }
  },

  data: () => ({
    items: [
      'Aposentado',
      'Aposentado com vínculo',
      'Ativo',
      'Em análise na operadora',
      'Em remissão',
      'Inativo',
      'Inativo com vínculo'
    ]
  }),

  computed: {
    filteredItems() {
      if (this.registeredStatus === 'Em análise na operadora') {
        return this.items.filter(
          x => x === 'Ativo' || x === 'Em análise na operadora'
        );
      } else {
        return this.items;
      }
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>
