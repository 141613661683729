<template>
  <v-text-field
    dense
    outlined
    clearable
    :label="label"
    :value="date"
    :error-messages="messages"
    v-on="$listeners"
    v-bind="$attrs"
    v-mask="customMask"
    @click:clear="clear"
    @change="onChange"
    @blur="onBlur"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      require: true
    }
  },

  data: () => ({
    messages: '',
    date: null,
    customMask: {
      mask: 'D#/M#/####',
      tokens: {
        D: {
          pattern: /[0-3]/
        },
        M: { pattern: /[0-1]/ },
        '#': { pattern: /\d/ }
      }
    },
    patternData:
      /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-](19|20|21)\d{2}$/
  }),

  mounted() {
    this.date = this.formatDate(this.value);
  },

  watch: {
    value(newValue) {
      if (newValue && newValue.length >= 10) {
        this.date = this.formatDate(newValue);
      }
    }
  },

  methods: {
    clear() {
      this.$nextTick(() => this.$emit('input', null));
    },

    onChange() {
      if (this.value) {
        this.setDate(this.value);
      }
    },

    onBlur(e) {
      if (e.target.value) {
        this.setDate(e.target.value);
      }
    },

    formatDate(date) {
      if (!date) {
        return null;
      } else {
        const dateSub = date.substring(0, 10);

        const [year, month, day] = dateSub.split('-');

        return `${day}/${month}/${year}`;
      }
    },

    setDate(value) {
      if (this.patternData.test(value)) {
        this.messages = '';

        const dateFormat = value.split('/');

        const valid = this.checkDate(
          parseInt(dateFormat[0]),
          parseInt(dateFormat[1]),
          parseInt(dateFormat[2])
        );

        if (valid) {
          const date = `${dateFormat[2]}-${dateFormat[1]}-${dateFormat[0]}T00:00:00`;

          this.$emit('change', date);
          this.$emit('input', date);
        }
      } else {
        this.messages = 'Valor do campo não é válido';
      }
    },

    checkDate(day, month, year) {
      const message = 'digite um dia menor ou igual a';

      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (day <= 31) {
            return true;
          } else {
            this.messages = `Para este mês, ${message} 31`;
            return false;
          }
        case 4:
        case 6:
        case 9:
        case 11:
          if (day <= 30) {
            return true;
          } else {
            this.messages = `Para este mês, ${message} 30`;
            return false;
          }
        case 2:
          if (year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)) {
            if (month === 2 && day > 29) {
              this.messages = `Ano bissexto, ${message} 29`;
              return false;
            } else {
              return true;
            }
          } else {
            if (month === 2 && day > 28) {
              this.messages = `Para este mês, ${message} 28`;
              return false;
            } else {
              return true;
            }
          }
      }
    }
  }
};
</script>
