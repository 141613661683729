<template>
  <v-btn
    depressed
    :block="block"
    :type="type"
    :color="color"
    :height="height"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon v-if="icon" left>{{ icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String
    },

    title: {
      type: String,
      require: true
    },

    type: {
      type: String,
      default: 'button'
    },

    color: {
      type: String,
      require: true
    },

    block: {
      type: Boolean,
      default: false
    },

    height: {
      type: String,
      default: '42'
    }
  }
};
</script>
