import getters from './getters';
import mutations from './mutations';

const tabs = [
  {
    order: 1,
    href: 'tab-1',
    title: 'Titular',
    icon: 'person',
    component: 'PageEmployee',
    visible: true,
    disabled: false
  },
  {
    order: 2,
    href: 'tab-2',
    title: 'Planos',
    icon: 'description',
    component: 'PagePlans',
    visible: true,
    disabled: true
  },
  {
    order: 3,
    href: 'tab-3',
    title: 'Dependentes',
    icon: 'groups',
    component: 'PageDependents',
    visible: true,
    disabled: true
  },
  {
    order: 4,
    href: 'tab-4',
    title: 'Cobrança',
    icon: 'attach_money',
    component: 'PageBilling',
    visible: true,
    disabled: true
  },
  {
    order: 5,
    href: 'tab-5',
    title: 'Documentos',
    icon: 'description',
    component: 'PageDocuments',
    visible: true,
    disabled: true
  },
  {
    order: 6,
    href: 'tab-6',
    title: 'Histórico',
    icon: 'source',
    component: 'PageHistory',
    visible: true,
    disabled: true
  }
];

const cards = [
  {
    title: 'Titular/Dependente',
    description:
      'Entrada e saída de funcionários, alteração de dados cadastrais dentro do cadastro de um estipulante ou subestipulante.',
    icon: 'fa-solid fa-user-gear',
    component: 'PageEmployee',
    visible: false
  },
  {
    title: 'Alteração de faixa etária',
    description:
      'Mudança necessária quando precisamos mudar a idade de um titular ou dependente.',
    icon: 'fa-solid fa-pager',
    component: 'PageAgeGroup',
    visible: false
  },
  {
    title: 'Alteração de plano',
    description:
      'Mudança do tipo plano ou de cobertura de acordo com os planos disponiveis em contrato.',
    icon: 'fa-solid fa-right-left',
    component: 'PagePlans',
    visible: false
  },
  {
    title: 'Remissão',
    description:
      'Possibilidade do beneficiário de um titular entre em remissão, para que os dependentes possam permanecer no plano.',
    icon: 'fa-solid fa-file-invoice',
    component: 'PageRemission',
    visible: false
  },
  {
    title: 'Aposentadoria de funcionário contributário',
    description: 'O beneficiário permanece no plano na condição de aposentado.',
    icon: 'fa-solid fa-umbrella-beach',
    component: 'PageRetirement',
    visible: false
  },
  {
    title: 'Demissão de funcionário contributário',
    description:
      'Solicitar desligamento do beneficiário contributário e solicitar a permanência no plano.',
    icon: 'fa-solid fa-user-xmark',
    component: 'PageResignation',
    visible: false
  },
  {
    title: 'Transferência de funcionário entre subestipulantes',
    description:
      'Transferência do contrato do funcionário e seus beneficiários entre estipulante e subestipulantes.',
    icon: 'fa-solid fa-shuffle',
    component: 'PageTransfer',
    visible: false
  }
];

export const movements = {
  namespaced: true,
  state: {
    employee: {},
    customerName: null,
    employeeHeader: {},
    cards: [],
    cardsFull: cards,
    tabs: tabs
  },
  getters,
  mutations
};
