<template>
  <v-autocomplete
    dense
    outlined
    placeholder="Digite para começar a pesquisar..."
    item-text="description"
    item-value="id"
    :loading="isLoading"
    :value="value"
    :search-input.sync="search"
    :items="items"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { ImplementationService } from '@/services/api/implementation';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    },
    filter: {
      type: String
    }
  },

  data: () => ({
    isLoading: false,
    search: null,
    items: []
  }),

  created() {
    if (this.filter) {
      this.get(this.filter);
    }

    if (this.profile && this.profile.customerName) {
      this.get(this.profile.customerName);
    }
  },

  computed: {
    ...mapGetters({
      profile: 'authentication/getProfile'
    })
  },

  methods: {
    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    },

    async get(filter) {
      this.isLoading = true;

      try {
        const service = new ImplementationService(false);
        const { status, data } =
          await service.getAvailableImplementationCustomers({
            filter
          });

        if (status === 200) {
          this.items = data;

          if (this.profile && this.profile.customerName) {
            this.$emit('input', this.items[0].id);
          }
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },

  watch: {
    search(newValue) {
      if (newValue && newValue.length >= 3) {
        this.get(newValue);
      }
    }
  }
};
</script>
