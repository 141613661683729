<template>
  <v-autocomplete
    dense
    outlined
    label="Dependente"
    persistent-placeholder
    placeholder="Digite para começar a pesquisar..."
    item-text="description"
    item-value="id"
    :loading="isLoading"
    :value="value"
    :search-input.sync="search"
    :items="items"
    v-on="$listeners"
    v-bind="$attrs"
    @input="onInput"
  >
    <template v-slot:prepend-inner>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="primary"> fa-solid fa-circle-info </v-icon>
        </template>
        <span>
          Ao pesquisar com CPF, digite conforme exemplo: 000.000.000-00
        </span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>
import { MovementService } from '@/services/api/movements';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    search: null,
    items: []
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event);
    },

    async get(newValue) {
      this.isLoading = true;

      try {
        const service = new MovementService(false);
        const { status, data } = await service.getAvailableDependents({
          filter: newValue,
          employeeId: this.employeeId
        });

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },

  watch: {
    search(newValue) {
      if (newValue && newValue.length >= 3) {
        if (newValue.includes(' - ')) {
          newValue = newValue.split(' - ')[1];
        }

        this.get(newValue);
      }
    }
  }
};
</script>
