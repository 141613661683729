import { HttpClient } from '@/services/http';

export class SupportService extends HttpClient {
  async getSupport(params) {
    const result = await this.get('v1/support', params);
    return result;
  }

  async create(model) {
    const params = new FormData();

    params.append('categoryId', model.categoryId);
    params.append('subject', model.subject);
    params.append('message', model.message);

    model.files.forEach(file => {
      params.append('files', file);
    });

    const result = await this.post('v1/support', params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return result;
  }

  async getById(ticketId) {
    const result = await this.get(`v1/support/${ticketId}`);
    return result;
  }

  async getCategories() {
    const result = await this.get('v1/support/categories');
    return result;
  }

  async getMessages(params) {
    const result = await this.get(
      `v1/support/${params.ticketId}/messages`,
      params
    );

    return result;
  }

  async postTicketMessage(model) {
    const params = new FormData();

    params.append('message', model.message);
    params.append('isDemandedTab', model.isDemandedTab);

    model.files.forEach(file => {
      params.append('files', file);
    });

    const result = await this.post(
      `v1/support/${model.ticketId}/messages`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async getFiles(params) {
    const result = await this.get(
      `v1/support/${params.ticketId}/files`,
      params
    );

    return result;
  }

  async downloadFiles(params) {
    const result = await this.get(
      `v1/support/${params.ticketId}/files/${params.fileId}`
    );

    return result;
  }

  async putTicketMessage(params) {
    const result = await this.put(
      `v1/support/${params.ticketId}/messages/${params.ticketMessageId}`,
      params
    );

    return result;
  }

  async getAvailableBusinessUnits() {
    const result = await this.get(`v1/support/demand/available-business-units`);
    return result;
  }

  async getDemandedResponsible(businessUnitId) {
    const result = await this.get(
      `v1/support/demand/business-unit/${businessUnitId}/available-responsible`
    );

    return result;
  }

  async postDemandUser(model) {
    const params = new FormData();

    params.append('demandedUserId', model.demandedUserId);
    params.append('message', model.message);

    model.files.forEach(file => {
      params.append('files', file);
    });

    const result = await this.post(
      `v1/support/${model.ticketId}/demand`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async putTicketSituation(params) {
    const result = await this.put(
      `v1/support/${params.ticketId}/situation`,
      params
    );

    return result;
  }

  async postTicketReturn(params) {
    const result = await this.post(
      `v1/support/${params.ticketId}/devolution`,
      params
    );

    return result;
  }

  async postResolveTicket(ticketId) {
    const result = await this.post(`v1/support/${ticketId}/resolve`);
    return result;
  }

  async postFinalizeTicket(params) {
    const result = await this.post(
      `v1/support/${params.ticketId}/finalize`,
      params
    );

    return result;
  }

  async postRejectTicket(model) {
    const params = new FormData();

    params.append('message', model.message);

    model.files.forEach(file => {
      params.append('files', file);
    });

    const result = await this.post(
      `v1/support/${model.ticketId}/reject`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async putChangeTicketResponsible(params) {
    const result = await this.put(
      `v1/support/${params.ticketId}/change-responsible/${params.demandedUserId}`
    );

    return result;
  }
}
