<template>
  <v-app :class="['biobe', !token && 'app-login']">
    <template v-if="!$route.meta.public && token">
      <Toolbar />
      <DrawerNotification />
      <AcceptTerm />

      <v-main>
        <transition name="slide" mode="out-in">
          <router-view :key="$route.fullpath" />
        </transition>
      </v-main>
    </template>

    <template v-else>
      <v-main>
        <keep-alive>
          <transition name="slide">
            <router-view :key="$route.fullpath" />
          </transition>
        </keep-alive>
      </v-main>
    </template>

    <Loader />
  </v-app>
</template>

<script>
import { meMixin } from '@/mixins/me';
import { themes } from '@/mixins/theme';
import { mapGetters } from 'vuex';

export default {
  mixins: [themes, meMixin],

  components: {
    Toolbar: () => import('@/components/header'),
    Loader: () => import('@/components/loader'),
    DrawerNotification: () => import('@/components/notification'),
    AcceptTerm: () => import('@/components/accept-term')
  },

  computed: {
    ...mapGetters({
      token: 'authentication/getToken',
      profile: 'authentication/getProfile'
    })
  },

  mounted() {
    this.theme();

    if (this.token && !this.profile) {
      this.getMe();
    }
  }
};
</script>
