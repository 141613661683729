import Swal from 'sweetalert2';
import Vue from 'vue';

export const showMessage = (type, message, timeout = 5000) => {
  if (!message) {
    throw new Error('Obrigatório passar como parâmetro uma mensagem!');
  }

  let newMessage = [];
  if (typeof message === 'string') {
    newMessage.push(message);
  } else if (Array.isArray(message)) {
    newMessage = message;
  } else {
    for (const [, value] of Object.entries(message)) {
      value.map(item => newMessage.push(item));
    }
  }

  newMessage.forEach(message => {
    Vue.$toast(message, {
      type,
      timeout,
      position: 'top-right',
      closeOnClick: false,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      showCloseButtonOnHover: false,
      closeButton: 'button',
      icon: true
    });
  });
};

export const confirmMessage = async (title, text) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      cancelButton: `v-btn v-btn--contained theme--dark v-size--default`,
      confirmButton: `v-btn v-btn--contained theme--dark v-size--default`
    },
    buttonsStyling: false
  });

  const result = await swalWithBootstrapButtons.fire({
    title,
    html: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    reverseButtons: true,
    cancelButtonText: 'Cancelar'
  });

  return result.value;
};
