<template>
  <v-text-field
    dense
    min="0"
    type="number"
    :outlined="outlined"
    :value="value"
    v-bind="$attrs"
    @input="onInput"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, null],
      require: true
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event ? parseInt(event) : event);
    }
  }
};
</script>
