import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/usuarios',
    meta: {
      public: false,
      title: 'Usuários'
    },
    name: 'usuarios',
    component: () => import('@/views/AccessControl/User/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['CC2'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    }
  },
  {
    path: '/unidades',
    meta: {
      public: false,
      title: 'Unidades de negócio'
    },
    name: 'unidades',
    component: () => import('@/views/AccessControl/BusinessUnit/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (checkPermission(['CC1', 'CC2'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    }
  }
];

export default routes;
