import { HttpClient } from '@/services/http';
import {
  configureRequest,
  objectWithoutProperties
} from '@/helpers/configureRequest';

export class ImplementationService extends HttpClient {
  async getImplementations(params) {
    const result = await this.get('v1/implementations', params);
    return result;
  }

  async create(params) {
    const result = await this.post('v1/implementations', params);
    return result;
  }

  async deleteImplementation(id) {
    const result = await this.delete(`v1/implementations/${id}`);
    return result;
  }

  async availableContracts(params) {
    const newParams = objectWithoutProperties(params, ['customerId']);

    const result = await this.get(
      `v1/implementations/customer/${params.customerId}/available-contracts`,
      newParams
    );

    return result;
  }

  async getAvailableImplementationCustomers(params) {
    const result = await this.get(
      `v1/implementations/customer/available-customers`,
      params
    );

    return result;
  }

  async getReview(params) {
    const result = await this.get(
      `v1/implementations/${params.implementationId}/review/step/${params.step}`
    );

    return result;
  }

  async createReview(params) {
    const result = await this.post(
      `v1/implementations/${params.implementationId}/review`,
      params
    );

    return result;
  }

  async updateReview(params) {
    const result = await this.put(
      `v1/implementations/${params.implementationId}/review`,
      params
    );

    return result;
  }

  async getImplantedLives(params) {
    const result = await this.get(
      `v1/implementations/${params.implementationId}/implanted-lives`
    );
    return result;
  }

  async downloadLivesFile(params) {
    const newParams = objectWithoutProperties(params, [
      'implementationId',
      'fileName'
    ]);

    const result = await this.get(
      `v1/implementations/${params.implementationId}/implanted-lives/${params.fileName}/download`,
      newParams
    );

    return result;
  }

  async downloadLayoutBiobe() {
    const result = await this.get(
      `v1/implementations/implanted-lives/unified-template/download`
    );

    return result;
  }

  async uploadDocumentImplantedLives(params) {
    let form = new FormData();
    form.append('file', params.file);

    const result = await this.post(
      `v1/implementations/${params.implementationId}/implanted-lives/${params.productType}/upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async getImplementationContracts(id) {
    const result = await this.get(`v1/implementations/${id}/contracts`);
    return result;
  }

  async putContractSituation(params) {
    const result = await this.put(
      `v1/implementations/${params.implementationId}/contracts/${params.contractId}`,
      params
    );

    return result;
  }

  async uploadImplementationContracts(params) {
    const newParams = objectWithoutProperties(params, [
      'implementationId',
      'contractId',
      'file'
    ]);

    const querystring = configureRequest(
      `v1/implementations/${params.implementationId}/contracts/${params.contractId}/upload`,
      newParams
    );

    let form = new FormData();
    form.append('file', params.file);

    const result = await this.post(querystring, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return result;
  }

  async finalizeImplementation(id) {
    const result = await this.post(`v1/implementations/${id}/finalize`);
    return result;
  }

  async getHistoryFiles(params) {
    const result = await this.get(
      `v1/implementations/${params.implementationId}/history-files`
    );

    return result;
  }

  async putHistoryFileSituation(params) {
    const result = await this.put(
      `v1/implementations/${params.implementationId}/history-files/${params.fileId}`,
      params
    );

    return result;
  }

  async downloadHistoryOrErrorFile(params) {
    const newParams = objectWithoutProperties(params, [
      'implementationId',
      'fileName'
    ]);

    const result = await this.get(
      `v1/implementations/${params.implementationId}/history-file/${params.fileName}/download`,
      newParams
    );

    return result;
  }

  async getImplementationFirstInvoiceDates(id) {
    const result = await this.get(`v1/implementations/${id}/first-dates`);
    return result;
  }

  async postImplementationFirstInvoiceDates(params) {
    const result = await this.post(
      `v1/implementations/${params.implementationId}/first-dates`,
      params.items
    );

    return result;
  }

  async getCardTemplateFile() {
    const result = await this.get('v1/implementations/cards/template-file');
    return result;
  }

  async getImplementationCardFiles(id) {
    const result = await this.get(`v1/implementations/${id}/cards/files`);
    return result;
  }

  async uploadImplementationCardFile(params) {
    let form = new FormData();
    form.append('file', params.file);

    const result = await this.post(
      `v1/implementations/${params.implementationId}/cards/upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async downloadCardFile(id) {
    const result = await this.get(
      `v1/implementations/cards/file/${id}/download`
    );
    return result;
  }
}
