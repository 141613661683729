import store from '@/store';

export const checkPermission = (roles, condition) => {
  const { permission } = store.getters['authentication/getProfile'];

  if (condition === '===') {
    return roles.includes(permission);
  } else if (condition === '!==') {
    return !roles.includes(permission);
  } else {
    return false;
  }
};
