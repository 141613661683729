<template>
  <v-row class="b-search">
    <v-col>
      <div class="b-search__content">
        <div class="b-search--title" v-if="title">
          {{ title }}

          <slot name="icon" />
        </div>
        <div class="b-search--form">
          <slot name="form" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: false
    }
  }
};
</script>

<style lang="scss">
.b-search {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    max-width: 100%;
    overflow-wrap: break-word;
    position: relative;
    white-space: normal;
  }

  &--title {
    font-size: 1rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
    letter-spacing: 0.0125em;
  }
}

.theme--light {
  .b-search {
    &--title {
      color: $gray--40;
    }

    &__content {
      box-shadow: 0px 0px 2px 1px $shadow-light;
      background-color: #fff;
    }
  }
}

.theme--dark {
  .b-search {
    &--title {
      color: #fff;
    }

    &__content {
      box-shadow: 0px 0px 2px 1px $shadow-dark;
      background-color: #363636;
    }
  }
}
</style>
