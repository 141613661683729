import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/faturas',
    name: 'faturas',
    meta: {
      public: false,
      title: 'Faturas'
    },
    component: () => import('@/views/OperationalManagement/Invoice/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2', 'C3'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    }
  }
];

export default routes;
