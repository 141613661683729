import { checkPermission } from '@/helpers/permission';

const routes = [
  {
    path: '/indexCustomers',
    meta: {
      public: false,
      title: 'Estipulante'
    },
    redirect: '/estipulantes',
    component: () =>
      import('@/views/OperationalManagement/Customers/Index.vue'),
    beforeEach: (to, from, next) => {
      if (checkPermission(['C1', 'C2'], '!==')) {
        next();
      } else {
        next({ path: '/403' });
      }
    },
    children: [
      {
        path: '/estipulantes',
        meta: {
          public: false,
          title: 'Estipulante'
        },
        name: 'estipulantes',
        component: () =>
          import('@/views/OperationalManagement/Customers/List.vue')
      },
      {
        path: '/estipulantes/create-or-edit/:id?/:sub?/:cnpj?/:socialName?/:parentId?',
        meta: {
          public: false,
          title: 'Visualizar estipulante'
        },
        name: 'estipulantes-edit',
        component: () =>
          import('@/views/OperationalManagement/Customers/Edit.vue')
      }
    ]
  }
];

export default routes;
