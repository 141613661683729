<template>
  <v-autocomplete
    item-text="description"
    item-value="id"
    dense
    outlined
    :loading="isLoading"
    :multiple="multiple"
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" class="caption">
        {{ item.description }}
      </span>
      <span v-if="index === 1" class="grey--text caption ml-1">
        (+{{ value.length - 1 }} contratos)
      </span>
    </template>

    <template v-slot:item="{ item, attrs, on }" v-if="multiple">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-simple-checkbox :value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.description }}
            <br />
            <small>{{ item.productType }}</small>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="item.situation === 'Cancelado'">
          <v-chip
            x-small
            color="red"
            text-color="white"
            class="font-weight-bold"
          >
            {{ item.situation }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </template>

    <template v-slot:item="{ item }" v-else>
      <div
        :class="[
          'w-full',
          item.situation === 'Cancelado' &&
            'd-flex align-center justify-space-between'
        ]"
      >
        <span class="caption">
          {{ item.description }}
          <br />
          <small>{{ item.productType }}</small>
        </span>
        <v-chip
          x-small
          color="red"
          text-color="white"
          class="font-weight-bold"
          v-if="item.situation === 'Cancelado'"
        >
          {{ item.situation }}
        </v-chip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { MovementService } from '@/services/api/movements';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Array, String],
      require: true
    },
    customerId: {
      type: String,
      require: true
    },
    productType: {
      type: String,
      require: false
    },
    multiple: {
      type: Boolean,
      require: false,
      default: true
    },
    includeInactive: {
      type: Boolean,
      require: false
    }
  },

  data: () => ({
    isLoading: false,
    items: []
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.isLoading = true;

      try {
        const service = new MovementService(false);
        const { status, data } = await service.getAvailableContracts({
          customerId: this.customerId,
          productType: this.productType
        });

        if (status === 200) {
          if (this.includeInactive) {
            this.items = data;
          } else {
            this.items = data.filter(x => x.situation !== 'Cancelado');
          }

          this.$store.commit('movements/setCustomerName', data);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    onInput(event) {
      this.$emit('input', event);
    },

    onChange() {
      this.$emit('change', this.value);
    }
  },

  watch: {
    customerId() {
      this.search();
    }
  }
};
</script>
