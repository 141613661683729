<template>
  <v-autocomplete
    dense
    outlined
    :items="items"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
    @input="onInput"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      require: true
    }
  },

  data: () => ({
    items: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO',
      'DF'
    ]
  }),

  methods: {
    onInput(event) {
      this.$emit('input', event);
    }
  }
};
</script>
